<template>
  <div class="find-content hnw-area-center" v-loading="$store.state.showLoding">
    <div class="classify-list" v-show="!isBatch">
      <div class="classify-head">
        <div class="classify-item">
          <img src="../../assets/new/findlist/classify/all.png" alt="" srcset="">
          全部分类
        </div>
      </div>
      <div class="classify-body">
        <div class="classify-item" :class="{'active':activeOert.find(item=>item==oert)}" v-for="(oert,index) in oesortList" :key="index" @click="handleScreen('activeOert',oert,'add')">
          <img  :src="require(`../../assets/new/findlist/classify/${oert}.png`)">
          {{ oert }}
        </div>
      </div>
    </div>
    <div class="find-body">
      <div class="body-head">
        <div class="head-left">

        </div>
        <div class="head-right" v-if="isBatch">
          <div class="group-item noin-item"  >
            <template v-if="noInquireList.length>0">
              <span class="no-btn">
                没有查到({{noInquireList.length}})
              </span>
              <el-button class="ex-btn" size="mini" text round type="danger" @click="exNoInquire('')">导出未查到</el-button>
            </template>
            <el-button  v-if="$store.state.batchs.length>0" class="ex-btn ex-btn1" size="mini" text round type="primary" @click="exNoInquire('all')">导出采购列表</el-button>
          </div>
        </div>

        <div class="head-right" v-else>
          <el-checkbox class="filterZero" v-model="sort_self" @change="query(1)" v-if="$store.getters.notLimitBrands" >自有品牌优先</el-checkbox>
          <el-checkbox class="filterZero"  v-model="showZero" @change="query(1)">过滤0库存</el-checkbox>

          <div class="up-down hnw-hov" >
              <div class="el-icon-caret-top" @click="shortCurpageChange(1)" :class="{'notCli':this.currentPage==1}">
              </div>
              <div class="el-icon-caret-bottom" @click="shortCurpageChange(2)" :class="{'notCli':this.currentPage==Math.ceil(this.total/this.pageSize)}">

              </div>
          </div>
        </div>
      </div>
      
     
      <template v-if="!isBatch">
        
        <div class="body-mode filter-show" v-show="activeOert.length>0||activeOenaturebrand.length>0">
          <div class="sx-row">
            <div class="row-l">
              筛选条件:
            </div>
          </div>
          <div class="sx-row" v-show="activeOert.length>0">
            <div class="row-l">
              全部分类:
            </div>
            <div class="row-r">
              <!-- activeOert -->
              <div class="sx-val" v-for="(oert,index) in  activeOert" :key="index">
                {{ oert }}
                <i class="el-icon-close" @click="handleScreen('activeOert',oert,'del')"></i>
              </div>
            </div>
          </div>
          <div class="sx-row" v-show="activeOenaturebrand.length>0">
            <div class="row-l">
              商品品牌:
            </div>
            <div class="row-r">
              <div class="sx-val" v-for="(oert,index) in  activeOenaturebrand" :key="index" >
                {{ oert }}
                <i class="el-icon-close" @click="handleScreen('activeOenaturebrand',oert,'del')"></i>
              </div>
            </div>
          </div>
        </div>
  
        <div class="body-mode filter-brand">
          <div class="sx-row">
            <div class="row-l">
              全部分类:
            </div>
            <div class="row-r">
              <div class="sx-val" :class="{'active':activeOenaturebrand.find(item=>item==oert)}"  v-for="(oert,index) in  oenaturebrandList" :key="index" @click="handleScreen('activeOenaturebrand',oert,'add')">
                {{ oert }}
               
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="body-mode goods-list">
        <div class="goods-row" v-for="goods in storeGoodsList" :key="goods.goods_id">
          <div class="goods-card">
            <div class="goods-left" >
              <div class="goods-name">
                {{goods.goods_name}} 
                <div class="tag-icon" v-if="goods.isExchange">
                    互
                </div>
                
              </div>
              <div class="letf-top" @click.stop="showGoodsInfo(goods)">
                <el-descriptions title="">
                    <el-descriptions-item label="OE号"> 
                      
                      {{goods.oecode}}
                      <HnwCopy :data="goods.oecode" :left="2" :top="-14"></HnwCopy>
                    </el-descriptions-item>
                    <el-descriptions-item label="生产编码">{{goods.sccode}}
                      <HnwCopy :data="goods.sccode" :left="2" :top="-14"></HnwCopy>
                    </el-descriptions-item>
                    <el-descriptions-item label="配件品牌">
                      <div class="goods-oenaturebrand">
                        {{goods.oenaturebrand}}
                      </div>
                    </el-descriptions-item>
                    <el-descriptions-item label="规格">{{goods.spec}}</el-descriptions-item>
                    <el-descriptions-item label="分类" >
                      
                      {{goods.oesort}}
                    </el-descriptions-item>

                    <el-descriptions-item label="订货价" contentClassName="goods-price">
                      <div class="symbol">￥</div>
                      {{goods.goods_price}}
                    </el-descriptions-item>
                    
                </el-descriptions>
              </div>
              <el-descriptions title="">
                <el-descriptions-item label="车型" contentClassName="goods-vehicletype">
                  <div :title="goods.vehicletype">{{goods.vehicletype}}</div>
                </el-descriptions-item>
              </el-descriptions>
              
              <div class="left-bottom">
                <el-descriptions title="" v-if="!$store.getters.isVisitor">
                    <el-descriptions-item label="库存" contentClassName="goods-storage">
                      
                      <template v-if="$store.state.member_id.includes('1780')">
                        
                        <div class="goods-location" > 
                          <div >上海:
                            <span v-if="$store.state.look_inv==1">{{goods.sh_storage}}</span>
                            <span v-else>{{goods.sh_storage>0?"有货":"无货"}}</span> 
                          </div>
                          <div>丹阳:
                            <span v-if="$store.state.look_inv==1">{{goods.dy_storage}}</span>
                            <span v-else>{{goods.dy_storage>0?"有货":"无货"}}</span> 
                          </div>
                        </div>
                        <div class="loca-btn" @click.stop="openAll(goods)" ><span>货位详情</span></div>
                      </template>

                      <div v-else>
                        <span v-if="$store.state.look_inv==1">{{goods.goods_storage}}</span>
                        <span v-else :class="{'disabled-color':goods.goods_storage==0}">{{goods.goods_storage>0?"有货":"无货"}}</span>

                        <span  class="goods-cangTip">{{goods.cangTip}}</span>
                      </div>
                    </el-descriptions-item>
                </el-descriptions>
                <div class="goods-ex-msg" v-if="goods.orderInfo">
                  <div class="buyHis"   @click.stop="readHisList(goods.goods_id)" >
                    您最近一次购买：
                    <span>
                        {{$hnwTools.formatDateTime(goods.orderInfo.add_time,'ymd')}}
                    </span>
                    <span>
                        {{goods.orderInfo.goods_num}} 件
                    </span>
                    <span class="readMore" >
                      更多
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="goods-right">
              <div class="body-item">
                <div class="goods-picture">
                  <HnwOssImg  :src="goods.goods_image" :preview="true" :width="120" :id="goods.goods_id"></HnwOssImg>
                </div>
                
              </div>
              <div class="goods-handle" v-if="!$store.getters.isVisitor">
                <div class="numAd">                       
                    <el-input-number   v-if="$store.state.zero_buy==1" v-model="goods.goods_num" :min="1"  size="mini"></el-input-number>
                    <el-input-number   v-else  v-model="goods.goods_num"  @change="impueNumChange(goods)" :min="1"  size="mini"></el-input-number>
                    <div class="goods-Fcl-tit" v-if="goods.brand_ratio_price">
                        整箱更优惠
                    </div>
                    <div class="goods-Fcl-tit red" v-if="goods.batch_pur_num_bey">
                        库存不足{{goods.batch_pur_num}}
                    </div>
                </div>
                

                <div class="btn add-btn" :class="{'ygd-btn':goods.goods_storage==0}" @click="joinCart(goods)">
                    {{goods.goods_storage>0?'加入购物车':'加入预购单'}}
                </div>
                <div class="addition">
                    <span v-if="goods.cartNum">
                        已添加 {{goods.cartNum}} 件
                    </span>
                </div>
                <el-popover
                  placement="left"
                  width="260"
                  popper-class="pop-replace-list"
                  v-model="goods.replacePopVis"
                  trigger="click">
                  <div class="pop-close"  @click.stop="goods.replacePopVis=false">
                    <i class="el-icon-close"></i>
                  </div>
                  <el-table  :border="false" class="replace-table"  :data="buyStoreList" height="300">
                    <el-table-column width="60" type="index" label="序号"></el-table-column>
                    <el-table-column width="120" property="oecode" label="互换件号">
                      <template slot-scope="scope">
                          <span >{{scope.row.oecode}}</span>
                          <HnwCopy :data="scope.row.oecode" ></HnwCopy>
                      </template>
                    </el-table-column>
                    <el-table-column width="80" property="address" label="操作">
                      <template slot-scope="scope">
                        <span class="show-btn" style="cursor: pointer" v-if="scope.row.goods_id" @click="toSearch(scope.row)">
                          在售
                        </span>
                      
                      </template>
                    </el-table-column>
                  </el-table>
                  
                  <div class="btn replace-btn" slot="reference" @click="showChangeParts(goods)" type="text"  v-if="goods.exchange.length>0">查看互换件</div>
                  
                </el-popover> 

              </div>

            </div>
          </div>
        </div>

        <div class="pagings" v-show="!isBatch" >             
            <el-pagination
                background
                @size-change="query(false)"
                @current-change="query(false)"
                :current-page.sync="currentPage"
                :page-sizes="[ 15,20, 30,40]"
                :page-size.sync="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="Math.ceil(this.total)">
            </el-pagination>
        </div>
        <div class="shoppingAll" v-show="isBatch">
            <div @click="joinCartAll">
                全部加入购物车
            </div>
        </div>
      </div>
    </div>

    <el-dialog title="历史购买记录" :visible.sync="hisVis" center  width="800px">
        <div class="his-list">
            <div class="list-head">
                <div>购买时间</div>
                <div>购买人</div>
                <div class="col-100">价格</div>
                <div class="col-100">购买个数</div>
                <div class="col-100">订单状态</div>
            </div>
            <div class="list-body">
                <div class="row" v-for="(his,index) in gooodsHisList" :key="index">
                    <div>{{$hnwTools.formatDateTime(his.add_time,'ymd')}}</div>
                    <div>{{his.member_name}}</div>
                    <div  class="col-100">{{his.goods_price}}</div>
                    <div  class="col-100">{{his.goods_num}}</div>
                    <div class="col-100">{{$hnwTools.getOrderStateText(his.order_state)}}</div>
                </div>
            </div>
        </div>
    </el-dialog>
    <el-dialog
      title="货位详情"
      :visible.sync="openAllotion"
      width="50%"
    >
      
      <div class="alltion">
        <div>OE号</div>
        <div>仓库</div>
        <div>货位</div>
        <div>库存</div>
      </div>

      <template v-if="goodsAllotion.dy.list.length>0">
        <div class="alltion">
          <div style="width: 100%;">丹阳</div>
        </div>
        <div class="alltion" v-for="(item) in goodsAllotion.dy.list" :key="item.shelfId">
          <div class="item">{{item.code}}</div>
          <div class="item">{{item.warehouseName}}</div>
          <div class="item">{{item.shelfName}}</div>
          <div class="item">{{item.shelfStockNum}}</div>
        </div>
      </template>
      <template v-if="goodsAllotion.sh.list.length>0">
        <div class="alltion">
          <div style="width: 100%;">上海</div>
        </div>
        <div class="alltion" v-for="(item) in goodsAllotion.sh.list" :key="item.shelfId">
          <div class="item">{{item.code}}</div>
          <div class="item">{{item.warehouseName}}</div>
          <div class="item">{{item.shelfName}}</div>
          <div class="item">{{item.shelfStockNum}}</div>
        </div>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="openAllotion = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="openAllotion = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
  </template>
  
  <script>
  import { export_json_to_excel } from "../../common/utils/Export2Excel"
  export default {
      data(){
          return{
            storeGoodsList: [], //搜索框数据
            // 历史购买价
            gooodsHisList: [],
            hisVis: false,
            showZero: false,
            sort_type: "goods_storage",
            sort_way: 1,
            sort_self: true,
            sortActive: "",
            openAllotion:false,//货位详情
            goodsAllotion:{
              dy:{
                  title:"丹阳",
                  list:[]
              },
              sh:{
                  title:"上海",
                  list:[]
              }
            },
            currentPage: 1,
            pageSize: 15,
            total: 0, //搜索总条数
            oesortList:[],
            oenaturebrandList:[],
            activeOert:[],
            activeOenaturebrand:[],
            searchData: {
              oenaturebrand: [],
              oesort: [],
            },
            //    互换件相关
            storeVis: false,
            replacePopVis:false,
            buyStoreList: [],
            noInquireList: [],
            aliasList: [],
            isBatch:false
          }
      },
      methods: {
        // 显示货位
        async readStockInfo(data){  
            if(this.$store.state.member_id.indexOf('1780-')!=-1){ 
                let allotion = await this.$http(`${this.$axios.defaults.baseURL.replace("web",'public')}index.php?act=search&op=stockInfo`,{skuId:data.skuId})
                this.goodsAllotion = this.$hnwTools.getAllotion(allotion) 
            }  
            
            this.openAllotion= true
        },
        async openAll(data){  
            // 显示货位
            if(this.$store.state.member_id.includes('1780')){ 
                let allotion = await this.$http(`${this.$axios.defaults.baseURL.replace("web",'public')}index.php?act=search&op=stockInfo`,{skuId:data.skuId})
                console.log("allotion",allotion);
                
                this.goodsAllotion = this.$hnwTools.getAllotion(allotion) 
            }  
            
            this.openAllotion= true
        },
        // 详情
        async showGoodsInfo(goods) {
          goods.preRou = "找件";
          localStorage.setItem("hnw-web-item-data", JSON.stringify(goods));
          this.$router.push("/find/info");
          
        },
        impueNumChange(goods) {
          if (isNaN(goods.goods_num)) {
            this.$nextTick(() => {
              this.$set(goods, "goods_num", 1);
            });
          }
          if (goods.goods_storage > 0 && goods.goods_num > goods.goods_storage) {
            this.$nextTick(() => {
              goods.goods_num = goods.goods_storage;
            });
            return this.$store.commit("UpDateState", { invDia: true });
          }
        },
        postDemand() {
          this.$router.push("/my/demand");
        },
       
       
        handleScreen(key,val,type){
          if(type=='add'){
            if(!this[key].find(item=>item==val)){
              this[key].push(val)
            }
          }else{
            this[key] = this[key].filter(item=>item!=val)
          }
          this.currentPage = 1
          this.query(false)
        },
        async exNoInquire(type){
                // 表头
          
            let tHeader = ["生产编码/OE号","订购数量"]
            let filterVal = ["code","number"]
            let filename = "查询失败配件"
            let array = [...this.noInquireList]
            // 如果是导出列表
            
            let msg =""
            try {
                if(type=='all'){
                  tHeader = ["OE号","商品名称","生产编码","配件品牌","订货价","订购数量","车型"]
                  filterVal = ["oecode","goods_name","sccode","oenaturebrand","goods_price","batch_pur_num","vehicletype"]
                  // batch_pur_num
                  array = [...this.storeGoodsList]
                  
                  console.log("array",array);
                  filename = "批量采购列表"
                }
                msg = this.$message({
                    message:'正在导出中....请不要离开本页面',
                    type: 'warning',
                    duration:0
                })
                
                let data = this.formatJson(filterVal,array)
                
                console.log("tHeader",tHeader)
                console.log("data",data)
                export_json_to_excel({
                    header:tHeader,
                    data,
                    filename,
                    autoWidth:true,
                    bookType:'xlsx'
                })
                msg.close()
            } catch (error) {
              this.$warMsg("导出失败")
              msg.close()
            }
            
            
        },
        formatJson(filterVal, tableData) {
            return tableData.map(v => {

                return filterVal.map(j => {
                        return v[j]
                    })
                }
            )
        },
        // 查看互换件 
        async showChangeParts(epcPart) {
          if(epcPart.exchange&&epcPart.exchange.length>0){
            let oem =  epcPart.exchange.join(",")
            this.buyStoreList = []
            let res = await this.$api.getBatchSearch({
              oem
            })
            console.log("epcPart",epcPart)
            console.log("res",res)
          
            if(res&&res.length>0){
              let addStr = ''
              res.forEach(goods=>{
                if(!addStr.includes(goods.oecode)){
                  this.buyStoreList.push({
                    goods_id:goods.goods_id,
                    oecode:goods.oecode
                  })
                  addStr += goods.oecode+","
                }
              })
            }
            epcPart.exchange.forEach(oecode=>{
              let has = this.buyStoreList.some(goods=>goods.oecode==oecode)
              if(!has){
                this.buyStoreList.push({
                  oecode
                })
              }
              
            })
            
            epcPart.replacePopVis = true
          }
        
        },
        // 加入购物车
        joinCart(carItem) { 
          // 
          const tidySubPart = async () => {
            let carMessage = {
              goods_id: carItem.goods_id,
              sccode: carItem.sccode,
              oecode: carItem.oecode,
              goods_name: carItem.goods_name,
              goods_price: carItem.goods_price,
              goods_num: carItem.goods_num,
              goods_storage: carItem.goods_storage,
              store_id: carItem.store_id,
              store_name: carItem.store_name,
              cart_type: "1"
            };

            let res =  await this.$api.joinCart([carMessage]);
            if(res){
              carItem.cartNum = Number(carItem.goods_num) + Number(carItem.cartNum)
            }
          };

          if (this.$isCompany()) {
            if(carItem.goods_storage>0){
              if(carItem.goods_num + Number(carItem.cartNum) >carItem.goods_storage){
                return this.$store.commit("UpDateState", { invDia: true });
              }else{
                tidySubPart();
              }
            }else{
              // 预购单提示
              if (carItem.goods_storage < 1 && Number(carItem.cartNum) > 0) {
                this.$affMsg("当前预购单已有该商品，是否继续添加?", tidySubPart);
              } else {
                tidySubPart();
              }
            }
          }
        },
        async joinCartAll() {
          let cart = [];

          if (this.storeGoodsList.length==0) {
            return this.$warMsg("没有任何商品");
          }
          this.storeGoodsList.forEach((goods) => {
            cart.push({
              goods_id: goods.goods_id,
              sccode: goods.sccode,
              oecode: goods.oecode,
              goods_name: goods.goods_name,
              goods_price: goods.goods_price,
              goods_num: goods.goods_num,
              goods_storage: goods.goods_storage,
              store_id: goods.store_id,
              store_name: goods.store_name,
              cart_type: "1",
             
            });
            
          });

          let res = await this.$api.joinCart(cart);
          
        },
        async replaceJoinCart(carItem) {
          this.storeVis = false;
     
          let carMessage = {
            goods_id: carItem.goods_id,
            sccode: carItem.sccode,
            oecode: carItem.oecode,
            goods_name: carItem.goods_name,
            goods_price: carItem.goods_price,
            goods_num: carItem.goods_num,
            goods_storage: carItem.goods_storage,
            store_id: carItem.store_id,
            store_name: carItem.store_name,
            cart_type: "1",
          };

          await this.$api.joinCart([carMessage]);
        },
        
        /*
          调用接口返回搜索结果
          * */
        toSearch(goods){
        
          this.$store.commit("UpDateState", {
            searchText: goods.oecode
          });
          this.storeVis = false
          goods.replacePopVis = false
        },
        async query(screen) {
          this.isBatch = false
          let search = "";
          search = this.$store.state.searchText;
          search = search.replace(this.$hnwTools.spec,"");
          search = search.trim();
          let sort_self = "";
          // 自有品牌
          
          if (this.sort_self) {
            if (this.$store.state.ratioBrandList) {
              this.$store.state.ratioBrandList.forEach((item) => {
                sort_self += item.name + ",";
              });
              sort_self = sort_self.substring(0, sort_self.length - 1);
            }
          } else {
            sort_self = "";
          }
          
          this.$store.commit("toggleLoding", true);
          let res = "";
          let isHhj = false
          let brandAlis = this.aliasList.find((item) =>
            item.like_name.includes(search)
          )
          
          let sort_type = this.sort_type
          if(this.$store.state.is_sh!=1){
            sort_type = "goods_stcids"
          }
          
          let searParams = {
              store_id: this.$store.state.store_id,
              member_id: this.$store.state.member_id,
              store_curpage: this.currentPage,
              store_page: this.pageSize,
              sort_type,
              sort_way: this.sort_way,
              sort_self,
              search,
              storage_type: this.showZero ? "ok" : "",
              type: screen ? "screen" : "",
             
              shop_type: this.$store.state.shop_type,
          }
          if(brandAlis){
            searParams.search = brandAlis.brand;
          }
          if(this.$store.state.searchOesort){
            searParams.oesort = this.$store.state.searchOesort
            searParams.search = ""
            searParams.oenaturebrand = this.activeOenaturebrand.join(",")
            // oenaturebrand
          }else{
            if(screen){
              this.activeOenaturebrand = []
              this.activeOert = []
            }
            searParams.oenaturebrand = this.activeOenaturebrand.join(",")
            searParams.oesort = this.activeOert.join(",")
          }
          res = await this.$api.getFindList({
            ...searParams
          });
          // 搜索非品牌并且存在互换件
          if(res.group&&res.group.length>0){
            isHhj = true
            searParams.search = ""
            res = await this.$api.getFindList({
                ...searParams,
                oecode: res.group.join(","),
              });
          }
          // if (search != ""||this.$store.state.searchOesort) {
           
            
          // }
          
          this.$store.commit("toggleLoding", false);
          if (!res || !res.data) {
            if (this.$store.state.searchText) {
              this.$warMsg("未搜索到任何商品");
            }
            return (this.storeGoodsList = []);
          }
          if(screen){
            try {
              if (this.currentPage==1) {
                this.oesortList = res.screen.oesort.map(item=>item.oesort) || []
                this.oenaturebrandList = res.screen.oenaturebrand.map(item=>item.oenaturebrand)||[]   
              } else {
                console.log(" 没 用 筛 选 值 ");
                this.oenaturebrandList = []
                this.oesortList = []
              }
              
            } catch (error) {
              console.log("筛选错误 ",error);
              this.oenaturebrandList = []
              this.oesortList = []
            }
          }
          
          let exOert = "非配件,未定义,国内品牌"
          this.oesortList = this.oesortList.filter(item=>!exOert.includes(item))
          try {
            this.total = res.data[0].total
            this.handleGoodsList(res,isHhj)
          } catch (error) {
            console.log("列表错误",error);
            this.storeGoodsList = []
            this.total = 0
          }
        },
        async searches() {
          let oecodes = "";
          this.isBatch = true
          this.$store.state.batchs.forEach((item) => {
            oecodes += item.search + ",";
          });
          const chesLoading = this.$loading({
            lock: true,
            text: '正在搜索...',
            spinner: 'el-icon-loading', 
          });
          
          setTimeout(() => {
            chesLoading.close();
            this.$store.commit("toggleLoding", false);
          }, 2000);
          oecodes = oecodes.substr(0, oecodes.length - 1);
          let res = await this.$api.getSearches({
            searchs: oecodes,

            store_id: this.$store.state.store_id,
          })
          if( res.data[0].return){
            this.noInquireList  = res.data[0].return.map(item=>{
              let noinqObj = this.$store.state.batchs.find(searItem=>searItem.search==item)
              console.log("noinqObj",noinqObj);
              
              let number = 1
              if(noinqObj){
                number = noinqObj.goods_storage
              }
              return {
                code:item,
                number
              }
            })
          }
          this.handleGoodsList(res,false,true)
        },
        handleGoodsList(res,isHhj,isBatch){
          let goodsList = []
          if(res.data.length>0){
            if(res.data[0].goods_list){
              goodsList = res.data[0].goods_list
            }
          }
          if(goodsList.length>0){
            goodsList.forEach(goods => {
              goods.goods_price = this.$handlePrice(goods);
              this.$set(goods, "goods_num", 1);
              if (!goods.self_storage) {
                goods.self_storage = 0;
              }
              if (!goods.cartNum) {
                goods.cartNum = 0;
              }

              if(goods.exchange){
                goods.exchange = goods.exchange.filter(item=>item!=goods.oecode)
                this.$set(goods,"replacePopVis",false)
              }else{
                goods.exchange = []
              }
              if(isHhj){
                goods.isExchange = true
              }
              if(this.$store.state.shop == 1780){
                this.$storageCangInfo(goods); 
                
              }
              if(isBatch){
                let obj = this.$store.state.batchs.find((searOe) => {
                let noRpOecode = goods.oecode.replace(/\s/g, "");
                let noRpSccode = goods.sccode.replace(/\s/g, "");
                let noRpEva = goods.evaluation_good_star.replace(/\s/g, "");
                let noRpCount = goods.evaluation_count.replace(/\s/g, "");
                if (
                  searOe.search == goods.oecode ||
                  goods.sccode == searOe.search ||
                  searOe.search == goods.evaluation_good_star ||
                  searOe.search == goods.evaluation_count
                ) {
                  return goods;
                } else if (
                  searOe.search == noRpOecode ||
                  noRpSccode == searOe.search ||
                  searOe.search == noRpEva ||
                  searOe.search == noRpCount
                ) {
                    return goods;
                  } else {
                    return "";
                  }
                });

                if (obj) {
                  if (Number(obj.goods_storage) > Number(goods.goods_storage)) {
                    this.$set(goods, "goods_num", Number(goods.goods_storage));
                    this.$set(goods, "batch_pur_num_bey", true);
                    this.$set(goods, "batch_pur_num", obj.goods_storage);
                  } else {
                    this.$set(goods, "batch_pur_num", obj.goods_storage);
                    this.$set(goods, "goods_num", obj.goods_storage);
                  }
                }
              }
            });
            if(isBatch){
              let arr1 = [];
              let arr2 = [];
              goodsList.forEach((goods) => {
                if (goods.batch_pur_num_bey) {
                  arr1.push(goods);
                } else {
                  arr2.push(goods);
                }
              });
              goodsList = [...arr1,...arr2]
            }
           
          }
          this.storeGoodsList = goodsList  
          
        },
        shortCurpageChange(type) {
          if (type == 1) {
            if (this.currentPage > 1) {
              this.currentPage--;
              this.query(false);
            } else {
              // this.$warMsg("已经第一个了")
            }
          } else {
            if (
              this.currentPage <
              this.total / this.pageSize
            ) {
              this.currentPage++;
              this.query(false);
            } else {
              // this.$warMsg("已经最后一页了")
            }
          }
        },
        // 购买记录
        async readHisList(goods_id) {
          let res = await this.$api.getGoodsRecord(goods_id);
          this.gooodsHisList = res;
          this.hisVis = true;
        },
      },
      watch: {
        
        "$store.state.searchText": {
          deep: true,
          handler(n) {
            this.currentPage = 1;
            this.pageSize = 15;

            this.query(1);
          },
        },
        "$store.state.batchs": {
          deep: true,
          // immediate:true,
          handler(n) {
            if (n.length > 0) {
              this.searches(true);
            }
          },
        },
      },
      async created(){
        this.$api.getAnalysisLog("search")
        // 获取品牌别名
        let aliasRes = await this.$api.getBrandAliasName({
          limit: "99",
        });
        this.aliasList = aliasRes.data;

        // 获取所有的平台店铺
        this.disStoreList = await this.$api.getDisAllStore();

        this.disStoreList.push({
          store_id: "1780",
          store_name: "华诺威汽车零部件（上海）股份有限公司",
        }); 
        

        
        if (this.$store.state.batchs.length > 0) {
         
          this.searches();
        } else {
          
          if (!this.$store.state.searchText) {
            // 获取历史记录
            let res1 =
              (await this.$api.getSearchRecord({
                record_type: 3,
              })) || []; 
            if (res1.length > 0) {
              this.$store.commit("UpDateState", {
                searchText: res1[0].record_name || "",
              });
            }else{
              this.$store.commit("UpDateState", {
                searchText: ""
              });
            }
          } 
          this.query(1);
         
        }
      },
      props:{
          
      }
  }
  </script>
  
  <style lang="scss">
  .find-index{
      background: #f9f9f9;
      padding-top: 10px;
      .active{
        color: #F83431;
      }
      .find-content{
        display: flex;
        justify-content: space-between;
        >div{
          // background: #fff;
          
          box-sizing: border-box;
        }
        .classify-list{
          width: 200px;
          background: #fff;
          border-right: 6px solid #f9f9f9;
          >div{
            box-sizing: border-box;
            padding-left: 30px;

          }
          .classify-head{
            border-bottom: 6px solid #f9f9f9;
          }
          .classify-item{
            display: flex;
            align-items: center;
            padding: 15px 0;
            cursor: pointer;
            img{
              margin-right: 6px;
              width: 14px;
            }
          }
          .classify-item:hover{
            color: #f00;
          }
        }
        .find-body{
          flex: 1;
          >div{
            border-bottom: 8px solid #f9f9f9;
            background: #fff;
            padding: 10px 30px;
            box-sizing: border-box;
          }
          .body-head{
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .head-right{
              display: flex;
              align-items: center;
              > .up-down {
                display: flex;
                flex-direction: column;
                padding-left: 5px;
                div {
                  cursor: pointer;
                }
                div:hover{
                  transform: scale(1.2);
                }
                .notCli {
                  cursor: not-allowed;
                }
              }
             
            }
            .noin-item{
              display: flex;
              align-items: center;
              .no-btn{
                margin-right: 5px;
              }
              .ex-btn{
                
              }
              .ex-btn1{
                
              }
            }
            .active {
              background: #3E84D6;
              // border: 1px solid #3E84D6;
              color: #fff;
              font-weight: bold;
            }
          }
          .body-mode{

            .sx-row{
              display: flex;
              align-items: center;
              min-height: 36px;
              .row-l{
                width: 60px;
                
                // height: 28px;
              }
              .row-r{
                margin-left: 20px;
                display: flex;
                flex-wrap: wrap;
                flex: 1;
                .sx-val{
                  margin-right: 9px;
                  border: 1px solid #063376;
                  line-height: 24px;
                  padding: 0 5px;
                  // margin-bottom: 10px;
                  .el-icon-close{
                    cursor: pointer;
                  }
                  i:hover{
                    color: #f00;
                  }
                }
              }
            }
            .pagings {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              margin: 20px 0;
            }
            .shoppingAll {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              margin: 20px 0;
              padding-right: 20px;
              > div {
                // width: 100px;
                // height: 40px;
                background-color: #3e84d6;
                color: #fff;
                padding: 0 5px;
                line-height: 40px;
                text-align: center;
                // border: 1px solid #000;
                border-radius: 5px;
                cursor: pointer;
              }
            }
          }
          .filter-brand{
            .sx-row{
              align-items: center;
              .row-r{
                max-height: 70px;
                overflow-y: auto;
                .sx-val{
                  border: none;
                  cursor: pointer;
                }
                .sx-val:hover{
                  color: #f00;
                }
              }
            }
          }
          .goods-list{
            padding: 0 ;
            .goods-row{
              border-bottom: 1px solid  #E7E7E7;;
              padding: 5px 30px;
              box-sizing: border-box;
              .goods-name{
                font-family: Source Han Sans CN;
                font-weight: 500;
                font-size: 16px;
                color: #333333;
                line-height: 24px;
                padding: 10px 0;
                display: flex;
                align-items: center;
                .tag-icon {
                  display: flex;
                  align-items: center;
                  // transform: scale(0.7);
                  justify-content: center;
                  width: 20px;
                  height: 20px;
                  background: $vin-blue;
                  color: #fff;
                  border-radius: 50%;
                  font-size: 12px;
                  position: relative;
                  top: -2px;
                  left: 8px;
                }
              }
              .goods-card{
                display: flex;
                .el-descriptions{
                  .el-descriptions-item__container{
                    align-items: center;
                  }
                  .el-descriptions-item{
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 14px;
                    color: #666666;
                    line-height: 1;
                    // line-height: 24px;
                    // padding-bottom: 0;
                    // height: 30px;
                  }
                }
                .goods-left{
                  flex: 1;
                  .letf-top{
                    cursor: pointer;
                    .goods-price{
                      color: #F94F4C;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      font-size: 18px;
                    }
                  }
                  .goods-oenaturebrand{
                    border-radius: 4px;
                    // border: 1px solid #0D347F; 
                    color: #0D347F;
                    font-size: 12px;
                    min-width: 40px;
                    height: 18px;
                    max-width: 140px;
                    overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: normal;
                    line-height: 18px;
                    text-align: center;
                    font-weight: 700;
                    font-size: 18px;
                  }
                  .goods-storage{
                    span{
                      font-weight: 700;
                      color: #333;
                      font-size: 16px;
                    }
                    .disabled-color{
                      color: #999;
                      font-weight: 400;
                      font-size: 14px;
                    }
                    .goods-cangTip{
                      color: #F94F4C;
                        font-size: 12px;
                        margin-left: 5px;
                        font-weight: bold;
                    }
                    .goods-location{
                      display: flex;
                      
                      >div{
                        display: flex;
                        align-items: center;
                        margin-right: 5px;
                      }
                    }
                    .loca-btn{
                      cursor: pointer;
                     
                      padding: 2px;
                      color: #59a8f8;
                      border: 1px solid #59a8f8;
                      width: 58px;
                    }
                  }
                  .goods-vehicletype{
                    >div{
                      width: 380px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: normal; /* Ensure normal white-space handling */
                      height: 14px;
                    }
                    }
                  .left-bottom{
                    position: relative;
                    
                    .goods-ex-msg {
                      display: flex;
                      
                      justify-content: flex-end;
                      align-items: center;
                      position: absolute;
                      right: 0;
                      top: 2px;
                      .buyHis {
                        cursor: pointer;
                        text-align: right;

                        padding: 0 5px;
                        color: red;
                        font-size: 12px;
                        > span {
                          margin-right: 10px;
                          align-items: center;
                        }
                        .readMore {
                          cursor: pointer;
                        }
                      }
                      .store-name {
                        padding: 0 5px;
                        color: #3E84D6;
                      }
                    }
                  }
                }
                .goods-right{
                  display: flex;
                  align-items: flex-end;
                  justify-content: space-between;
                  width: 290px;
                  padding-bottom: 10px;
                  .body-item{
                    text-align: center;
                    .goods-picture{
                      padding-bottom: 10px;
                    }
                    .numAd{

                    }
                  }
                  .goods-handle{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                    .addition {
                      padding-top: 5px;
                      font-size: 14px;
                      color: #999;
                    }
                    .btn{
                      width: 112px;
                      height: 34px;
                      
                      background: rgba(6,51,118,0.1);
                      border-radius: 4px;
                      border: 1px solid #063376;
                      color: #063376;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      font-size: 16px;
                      text-align: center;
                      line-height: 34px;
                      cursor: pointer;
                      margin-top: 10px;
                    }
                    .add-btn{
                      color: #F83431;
                      border: 1px solid #F83431;
                      background: #FEEBEA;
                    }
                    
                    .replace-btn{

                    } 
                  }
                }
              }
            }
          }
        }
        .el-dialog__body {
          padding: 0 !important;

          .his-list {
            .list-head {
              display: flex;
              height: 40px;
              align-items: center;
              background: #eeeeee;
              margin: 10px 0;
              padding: 0 8px;
              > div {
                flex: 1;
                text-align: center;
              }
              .col-100 {
                max-width: 100px;
              }
            }
            .list-body {
              .row {
                display: flex;
                padding: 0 8px;
                > div {
                  flex: 1;
                  padding: 2px 0;
                  border-bottom: 1px solid #eee;
                  flex: 1;
                  text-align: center;
                  line-height: 28px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .col-100 {
                  max-width: 100px;
                }
              }
            }
          }
        }
      }

  }
  </style>